export function seo(data = {}) {
	// Set title
	if (typeof data.title !== "undefined") {
		document.title = data.title;
	}

	// Helper function to set or update a meta tag
	const setMetaTag = (name, content) => {
		let metaTag = document.querySelector(`meta[name="${name}"]`);
		if (!metaTag) {
			// Create the meta tag if it doesn't exist
			metaTag = document.createElement("meta");
			metaTag.setAttribute("name", name);
			document.head.appendChild(metaTag);
		}
		metaTag.setAttribute("content", content);
	};

	// Set meta description
	if (data.description) {
		setMetaTag("description", data.description);
	}

	// Set meta keywords
	if (data.keywords) {
		setMetaTag("keywords", data.keywords);
	}
}

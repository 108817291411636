import React from "react";
import { Route } from "react-router-dom";
import * as Constants from "../config/constants";
import SamplePage from '../pages/design/design-sample';
import Home from '../pages/design/home';
import ContactUs from '../pages/design/contact-us';
import DesignBlogs from '../pages/design/blogs';
import DesignBlogDetails from '../pages/design/blog-details';

const DesignRoute = [
    <Route key="design-1" path={Constants.SAMPLE_PAGE_URL} element={<SamplePage />} />,
    <Route key="home-page" path={Constants.DESIGN_HOME_PAGE_URL} element={<Home />} />,
    <Route key="contact-us" path={Constants.DESIGN_CONTACT_US_PAGE_URL} element={<ContactUs />} />,
    <Route key="blogs" path={Constants.DESIGN_BLOGS_PAGE_URL} element={<DesignBlogs />} />,
    <Route key="blog-details" path={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} element={<DesignBlogDetails />} />
];

export default DesignRoute;
import React from "react";
import {Link} from "react-router-dom";
import Layout from "../layout";
import * as Constants from "../../config/constants";
import { useTranslation } from "react-i18next";
import blogImg from "../../assets/images/blog-img.png";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import BlogsCard from "../../components/blogsCard";
// ..
AOS.init();

export default function DesignBlogs() {

  const {t} = useTranslation();

  return (
    <Layout pageName={t("blogs")}>
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">Blogs / Category / Tag Name / Showing Results For : Lorem</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{t("blogs")}</li>
          </ol>
        </div>
      </header>

      <section className="blogs-list-sec">
        <div className="container">
          <div className="inner-content">
            <div className="top-sec" data-aos="fade-right" data-aos-delay="300">
              <div className="search-div"><input type="text" className="form-control" name="search_blogs" placeholder="Search..." /> <button type="button" className="search-btn"><i className="fa-solid fa-magnifying-glass icn"></i></button></div>
              <div className="category-list">
                <button type="button" className="category-btn active">All</button>
                <button type="button" className="category-btn">Cyber Security</button>
                <button type="button" className="category-btn">Cyber Security</button>
                <button type="button" className="category-btn">Cyber Security</button>
                <button type="button" className="category-btn">Cyber Security</button>
                <button type="button" className="category-btn">Cyber Security</button>
                <button type="button" className="category-btn">Cyber Security</button>
                <button type="button" className="category-btn">Cyber Security</button>
                <button type="button" className="category-btn">Cyber Security</button>
              </div>
              <select name="" className="category-select form-select form-control">
                <option value="">All</option>
                <option value="Cyber Security">Cyber Security</option>
                <option value="Cyber Security">Cyber Security</option>
                <option value="Cyber Security">Cyber Security</option>
                <option value="Cyber Security">Cyber Security</option>
                <option value="Cyber Security">Cyber Security</option>
              </select>
            </div>
            <div className="bottom-sec" data-aos="fade-left" data-aos-delay="300">
              <div className="blogs-row">
                <BlogsCard imgSrc={blogImg} title={"Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text"} text={"Lorem Ipsum is simply dummy text of the printing and typesetting industryIpsum is simply dummy text of the printing and typesetting industryIpsum is simply dummy text of the printing and typesetting industryIpsum is simply dummy text of the printing and typesetting industryIpsum is simply dummy text of the printing and typesetting industry"} date={"16-Jul-2024"} category={"Cyber SecurityCyber SecurityCyber SecurityCyber SecurityCyber Security"} author={"Raymond Delacruz"} readTime={"5 Mins Read"} to={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} link={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} />
                <BlogsCard imgSrc={blogImg} title={"Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text"} text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry"} date={"16-Jul-2024"} category={"Cyber Security"} author={"Raymond Delacruz"} readTime={"5 Mins Read"} to={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} link={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} />
                <BlogsCard imgSrc={blogImg} title={"Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text"} text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry"} date={"16-Jul-2024"} category={"Cyber Security"} author={"Raymond Delacruz"} readTime={"5 Mins Read"} to={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} link={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} />
                <BlogsCard imgSrc={blogImg} title={"Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text"} text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry"} date={"16-Jul-2024"} category={"Cyber Security"} author={"Raymond Delacruz"} readTime={"5 Mins Read"} to={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} link={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} />
                <BlogsCard imgSrc={blogImg} title={"Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text"} text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry"} date={"16-Jul-2024"} category={"Cyber Security"} author={"Raymond Delacruz"} readTime={"5 Mins Read"} to={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} link={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} />
                <BlogsCard imgSrc={blogImg} title={"Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text"} text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry"} date={"16-Jul-2024"} category={"Cyber Security"} author={"Raymond Delacruz"} readTime={"5 Mins Read"} to={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} link={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} />
              </div>
              <button type="button" className="blog-btn m-auto" title="View More">View More</button>
              <div className="no-record-div">
                <h6 className="no-record-text">No Record Found</h6>
                <button type="button" className="blog-btn" title="Go To Blog">Go To Blog</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
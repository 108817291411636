import React, {useState, useEffect}  from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Constants from "../../config/constants";
import { alertifyMessage, apiCall , emailRegex, manageNetworkError } from "../../assets/js/common";
import BlogsLayout from "./blogs-layout";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { seo } from "../../seo";

export default function BlogDetails() {
  const { t } = useTranslation();
  const { t: errorsMessage } = useTranslation('errorMessage');
  const { slug } = useParams();
  const navigate = useNavigate();
  const [recordInfo, setRecordInfo] = useState({
    blogs: [],
    tags: [],
    categories: [],
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError
  } = useForm({
    defaultValues: {
      email: "",
      recived_content: Constants.SELECTION_YES
    }
  });

  const getRecordDetails = async() => {
    const getRecordDetail = await apiCall('get', 'blog-page/' + slug.replace('.html', ''), null, false, { isPageRequest: true, navigate: navigate });
    if (getRecordDetail?.status !== false) {
      if (getRecordDetail?.data?.data) {
        setRecordInfo(getRecordDetail?.data?.data);
      }
    } else {
      manageNetworkError(getRecordDetail);
    }
  }

  useEffect(() => {
		getRecordDetails();
    // eslint-disable-next-line
  }, [slug]);

  const onSubmit = async (data) => {
    data.category_id = recordInfo?.blogDetails?.category_id;
    data.blog_id = recordInfo?.blogDetails?.id;
    data.recived_content = data?.recived_content ? Constants.SELECTION_YES: Constants.SELECTION_NO;

    var check = await emailUniqueCheck(data);
    if (check) {
      return false;
    }

    var submitContact = await apiCall('post', 'subscribe-newsletter', data);
    if (submitContact?.status !== false) {
      if (submitContact?.data?.status_code === 1) {
        alertifyMessage("success", submitContact?.data?.message);
        reset();
      } else {
        alertifyMessage("error", submitContact?.data?.message);
      }
    } else {
      manageNetworkError(submitContact);
    }
  }

  const emailUniqueCheck = async (data) => {
    const uniqueCheck = await apiCall('post', 'email-unique-check', data);
    if (uniqueCheck.status !== false) {
      if (uniqueCheck?.data?.status_code === 1) {
        return false;
      } else {
        setError("email", { message: uniqueCheck?.data?.message });
        return true;
      }
    } else {
      manageNetworkError(uniqueCheck);
    }
  }

  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch
  } = useForm();

  const onSearchSubmit = (data) => {
    if (data?.search) {
      navigate("/" + Constants.BLOG_SEARCH_PAGE_URL + "?s=" + data?.search);
    }
  }

  useEffect(() => {
    seo({
      description: recordInfo?.blogDetails?.meta_description,
      keywords: recordInfo?.blogDetails?.meta_keywords
    });
  }, [t, recordInfo?.blogDetails]);

  const onClickCategoryButton = (e, notShowText = false, isSelectField = false) => {
    if (e?.target?.innerText && !notShowText) {
      if (isSelectField) {
        navigate(Constants.CATEGORY_PAGE_URL + "/" + e?.target?.value + ".html");
      }
    } else {
      if (isSelectField) {
        navigate(Constants.BLOGS_PAGE_URL);
      }
    }
  }

  return (
    <BlogsLayout
      pageName={recordInfo?.blogDetails?.title}
      categoryDetails={recordInfo?.categories || []}
      headingTitle={recordInfo?.blogDetails?.title}
      navLinkHtml={
        <li className="breadcrumb-item active" aria-current="page"><Link to={Constants.BLOGS_PAGE_URL} className="breadcrumb-item" aria-current="page">{t("blogs")}</Link></li>
      }
      hideSearch={true}
      onClickCategoryButton={onClickCategoryButton}
      showRecommendedPosts={true}
      recommendedPosts={recordInfo?.recommendedPosts}
    >
      <div className="row blog-row">
        <div className="col-lg-8" data-aos="fade-down" data-aos-delay="300">
          <div className="blog-detail-card">
            <div className="top-content">
              <img src={recordInfo?.blogDetails?.image} alt={recordInfo?.blogDetails?.title} className="img-fluid blog-detail-img" />
              <div className="info">
                <div className="left-cnt">
                  {recordInfo?.blogDetails?.publish_date && <h5 className="info-text"><i className="fi fi-rr-calendar-day icon"></i>{recordInfo?.blogDetails?.publish_date}</h5>}
                  {recordInfo?.blogDetails?.category && <Link to={Constants.CATEGORY_PAGE_URL + "/" + recordInfo?.blogDetails?.category_slug + ".html"} className="info-text"><i className="fi fi-rr-list icon"></i>{recordInfo?.blogDetails?.category}</Link>}
                  {recordInfo?.blogDetails?.author && <h5 className="info-text"><i className="fi fi-rr-user icon"></i>{recordInfo?.blogDetails?.author}</h5>}
                </div>
                <div className="share-div">
                  <h5 className="title">Share:</h5>
                  <div className="contact-social">
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer" className="social-div"><i className="fi fi-brands-facebook icn"></i></a>
                    <a href={`https://api.whatsapp.com/send?text=${window.location.href}`} target="_blank" rel="noopener noreferrer" className="social-div"><i className="fi fi-brands-whatsapp icn"></i></a>
                    <a href={`https://x.com/intent/post?url=${window.location.href}`} target="_blank" rel="noopener noreferrer" className="social-div"><i className="fi fi-brands-twitter-alt icn"></i></a>
                    <a href={`https://www.linkedin.com/sharing/share-offsite?url=${window.location.href}`} target="_blank" rel="noopener noreferrer" className="social-div"><i className="fa-brands fa-linkedin icn"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-content">
              <div dangerouslySetInnerHTML={{__html: recordInfo?.blogDetails?.content}}></div>
              {(recordInfo?.blogDetails?.pdf_file_path || recordInfo?.blogDetails?.pdf_file_description) && <div className="download-pdf-div">
                <a href={recordInfo?.blogDetails?.pdf_file_path} target="_blank" rel="noreferrer" type="button" className="blog-btn" title="Download PDF"><i className="fi fi-rr-file-pdf icn"></i> Download PDF</a>
                {recordInfo?.blogDetails?.pdf_file_description && <p className="text">{recordInfo?.blogDetails?.pdf_file_description}</p>}
              </div>}
            </div>
          </div>
        </div>
        <div className="col-lg-4" data-aos="fade-down" data-aos-delay="300">
          <div className="right-content">
            <div className="search-div right-cnt-search">
              <form onSubmit={handleSubmitSearch(onSearchSubmit)}>
                <input type="text" className="form-control" name="search" {...registerSearch("search")} placeholder="Search..." />
                <button type="submit" className="search-btn">
                  <i className="fa-solid fa-magnifying-glass icn"></i>
                </button>
              </form>
            </div>
            {(recordInfo?.blogDetails?.blog_tag && recordInfo?.blogDetails?.blog_tag?.length > 0) && <div className="items">
              <h6 className="item-title">Tags</h6>
              <div className="tags-card">
                {recordInfo?.blogDetails?.blog_tag?.map((blogTag, key) => (
                  <Link key={key} to={`${Constants.TAG_PAGE_URL}/${blogTag?.slug}.html`} type="button" className="tag">#{blogTag?.tag}</Link>
                ))}
              </div>
            </div>}
            <div className="items">
              <h6 className="item-title">Subscribe To Our Newsletter</h6>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="subscribe-div">
                  <div className="w-100">
                    <input type="text" className="form-control" placeholder="Enter Your Email Address..." name="email" onBlur={(e) => emailUniqueCheck({ email: e?.target?.value })} {...register("email", { required: errorsMessage('required-enter-email-address'), setValueAs: value => value.trim(), pattern: emailRegex(t('email-address')) })} />
                    {errors?.email && (
                      <span className="text-white">{errors?.email?.message}</span>
                    )}
                  </div>
                  <label className="checkbox-div" htmlFor="recived_content">
                    <input type="checkbox" className="form-check-input" name="recived_content" id="recived_content" {...register("recived_content", { required: false, setValueAs: value => value ? Constants.SELECTION_YES: Constants.SELECTION_NO })}/>
                    Send me an email when you have new things
                  </label>
                  <button type="submit" className="sub-btn" title="Subscribe">Subscribe</button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>
    </BlogsLayout>
  );
}
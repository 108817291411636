import React, { useEffect , useState } from "react";
import Header from './includes/header';
import Footer from './includes/footer';
import { seo } from '../seo';
import $ from 'jquery';
import * as Constants from "../config/constants";  
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {  apiCall , manageNetworkError } from "../assets/js/common";
window.jQuery = $;
window.$ = $;
global.jQuery = $;


export default function Layout({ children , pageName, onClickCategoryButton }) {
  const { t } = useTranslation(["translation", "errorMessage"]);
  const [settingInfo, setSettingInfo] = useState([]);
  const user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
  
  const getSettingDetails = async () => {
		const getSettingInfo = await apiCall(  'post' ,  'getSettingInfo'  );
		if( getSettingInfo.status !== false ){
			setSettingInfo(getSettingInfo?.data?.data?.settingInfo ?? {});
		} else {
			manageNetworkError(getSettingInfo);
		}
	}

  const updateTotalVisitor = async () => {
    
    const response = await apiCall( 'post' , Constants.UPDATE_TOTAL_VISITOR_URL , null , false);
    if(response?.status !== false) {
      if(response?.data?.status_code === 1) {
        const userData = ((user && user?.token) ? user : {});
        if(response?.data?.data) {
          userData.hasVisited = response.data.data?.hasVisited;
          localStorage.setItem( Constants.LOGIN_COOKIE_NAME , JSON.stringify(userData));
        }
      }
    } else {
        manageNetworkError(response);
    }
  }

  useEffect(() => {
    getSettingDetails();
  } , []);

  useEffect(() => {
    if( !user || !user.hasVisited || user.hasVisited !== Constants.SELECTION_YES ) {
      updateTotalVisitor();
    }
    // eslint-disable-next-line
  } , [user]);
  
  useEffect(() => {
    seo({
      title: pageName + " | " + (typeof(settingInfo.site_title) !== "undefined" && settingInfo.site_title !== null && settingInfo.site_title !== "" ? settingInfo.site_title : Constants.SITE_TITLE),
      description: settingInfo?.site_description ?? '',
      keywords: settingInfo?.site_keywords ?? ''
    });
  }, [t,settingInfo, pageName]);
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name='title' content={(pageName !== null && pageName !== "" ? pageName : 'Home' ) + ' | ' + (settingInfo.site_title !== null && settingInfo.site_title !== "" ? settingInfo.site_title : "")} />
        <meta name='description' content={settingInfo.site_description !== null && settingInfo.site_description !== "" ? settingInfo.site_description : ""} />
        <meta name='keywords' content={settingInfo.site_keywords !== null && settingInfo.site_keywords !== "" ? settingInfo.site_keywords : ""} />
        <meta name='author' content={settingInfo.meta_author !== null && settingInfo.meta_author !== "" ? settingInfo.meta_author : ""} />
        <meta name='copyright' content={settingInfo.site_title !== null && settingInfo.site_title !== "" ? settingInfo.site_title : ""} />
        <meta name='publisher' content={settingInfo.meta_author !== null && settingInfo.meta_author !== "" ? settingInfo.meta_author : ""} />
        
        <meta property='og:title' content={settingInfo.site_title !== null && settingInfo.site_title !== "" ? settingInfo.site_title : ""} />
        <meta property='og:description' content={settingInfo.site_description !== null && settingInfo.site_description !== "" ? settingInfo.site_description : ""} />
        <meta property="og:url" content={Constants.SITE_URL} />
        <meta property='og:site_name' content={settingInfo.site_title !== null && settingInfo.site_title !== "" ? settingInfo.site_title : ""} />
        {settingInfo.website_og_icon !== null && settingInfo.website_og_icon !== "" && (
          <meta property='og:image' content={Constants.BACKEND_FILE_URL + settingInfo.website_og_icon} />
        )}

        <meta property="twitter:url" content={Constants.SITE_URL} />
        <meta property='twitter:title' content={settingInfo.site_title !== null && settingInfo.site_title !== "" ? settingInfo.site_title : ""} />
        <meta property='twitter:description' content={settingInfo.site_description !== null && settingInfo.site_description !== "" ? settingInfo.site_description : ""} />
        {settingInfo.website_logo !== null && settingInfo.website_logo !== "" && (
          <meta property='twitter:image' content={Constants.BACKEND_FILE_URL + settingInfo.website_logo} />
        )}
        
        {settingInfo.website_fav_icon !== null && settingInfo.website_fav_icon !== "" && (
          <link rel="icon" href={Constants.BACKEND_FILE_URL + settingInfo.website_fav_icon} />
        )}
        {settingInfo.website_fav_icon !== null && settingInfo.website_fav_icon !== "" && (
          <link rel="apple-touch-icon" href={Constants.BACKEND_FILE_URL + settingInfo.website_fav_icon} />
        )}
      </Helmet>

      <Header settingInfo={settingInfo} onClickCategoryButton={onClickCategoryButton}/>
      <main>{children}</main> 
      <Footer settingInfo={settingInfo}/>
    </>
  )
}
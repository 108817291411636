import React, { useState,useEffect } from "react";
import {Link  } from "react-router-dom";
import Layout from "../pages/layout";
import * as Constants from "../config/constants";
import {decode} from 'html-entities';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {  apiCall ,  alertifyMessage , manageNetworkError, emailRegex, validationError, showLoader, hideLoader } from "../assets/js/common";


import ContactImg from "../assets/images/contact-img.svg";
import Icon1 from "../assets/images/location.svg";
import Icon2 from "../assets/images/mail.svg";
import Icon3 from "../assets/images/phone.svg";


import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {

  const {t} = useTranslation(["translation", "errorMessage"]);

  const [inputMobileValue, setInputMobileValue] = useState("");

  const handleMobileInputChange = (e) => {
    const value = e.target.value;
    const numericValue = value.trim().replace(/[^ +\d]/g, "");
    setInputMobileValue(numericValue);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  
  const onSubmit = async(data) => {
	  
	showLoader();
	
	var submitContact = await apiCall(  'post' ,  '/submitContact' , data);
	
	if( submitContact.status !== false ){
		if(submitContact.data.status_code === 1) {
			alertifyMessage("success" ,  submitContact.data.message );
	        setInputMobileValue("");
	        reset();
    	} else {
    		alertifyMessage("error" ,submitContact.data.message );
    	}
	} else {
		manageNetworkError(submitContact);
	}
	
	hideLoader();
  };
  const [settingInfo , setSettingInfo] = useState([]);

  const getSettingDetails = async () => {
		const getSettingInfo = await apiCall( 'post' ,  'getSettingInfo' );
		if( getSettingInfo.status !== false ){
			setSettingInfo(getSettingInfo.data.data.settingInfo);
		} else {
			manageNetworkError(getSettingInfo);
		}
	}
  useEffect( () => {
	  getSettingDetails(); 
  } , []);  

  return (
    <Layout pageName={t("contact-us")}>
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">Contact Us</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{t("contact-us")}</li>
          </ol>
        </div>
      </header>

      <section className="section-padding">
       <div className="container">
         <div className="row row-gap20">
           <div className="col-lg-7 pr-15">
              <div className="custome-card" data-aos="fade-right" data-aos-delay="300">
                <h3 className="main-title">Send us <span>a Message</span></h3>
                <form onSubmit={handleSubmit(onSubmit)} id="contact-us-form" autoComplete="off">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="name" className="form-label">{t('name')}<span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" name="name" placeholder={t('name')}
                            {...register("name", {
                              required: t('errorMessage:required-enter-name'),
                              setValueAs: (value) => value.trim(),
                            })}/>
                          {validationError(errors.name)}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="mobile_number" className="form-label">{t('mobile-no')}</label>
                          <input type="tel" className="form-control" placeholder={t('mobile-no')} minLength="8" maxLength="15" value={inputMobileValue} name="mobile_number"
                            {...register("mobile_number", {
                              required: false,
                              setValueAs: (value) => value.trim()
                            })}
                            onChange={handleMobileInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="email" className="form-label">
                            {t('email')}<span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" name="email" placeholder={t('email')}
                            {...register("email", {
                              required: t('errorMessage:required-enter-email'),
                              pattern: emailRegex()
                            })}
                          />
                          {validationError(errors.email)}
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="text" className="form-label">{t('message')}</label>
                          <textarea className="form-control" name="message" defaultValue={""} placeholder={t('message')}
                            {...register("message", { required: false })}/>
                        </div>
                      </div>
                      <div className="col-lg-12 d-none">
                        <div className="form-group">
                          <label htmlFor="webname">{t('webname')}</label>
                          <input type="text" name="webname" className="form-control" id="webname" placeholder={t('webname')}
                            {...register("webname", { required: false })}/>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group d-flex mb-0">
                          <button type="submit" name="submit" title={t('send')} className="common-btn submit-btn ms-auto">{t('send')}</button>
                        </div>
                      </div>
                    </div>
                  </form>
              </div>
           </div>
           <div className="col-lg-5 pl-15 detail-card">
              <div className="h-50 d-lg-flex d-none" data-aos="fade-down" data-aos-delay="300">
                <img src={ContactImg} alt="Contact" className="contact-img"/>
              </div>
              {((settingInfo.address && settingInfo.address !== null) || (settingInfo.email && settingInfo.email !== null) || (settingInfo.primary_mobile_no && settingInfo.primary_mobile_no !== null)) && (
                <>
                  <div className="custome-card" data-aos="fade-up" data-aos-delay="300">
                    {settingInfo?.address && settingInfo.address !== null && (
                      <div className="detail-mdiv">
                        <div className="detail-icon">
                          <img src={Icon1} alt="Address"/>
                        </div>
                        <div className="contact-detail">
                          <span className="contact-detail-title">India - </span>
                            <span className="contact-detail-link" dangerouslySetInnerHTML={{__html: decode(settingInfo.address)}}></span>
                        </div>
                      </div>
                    )}
                    <div className="detail-mdiv">
                      <div className="detail-icon">
                        <img src={Icon1} alt="Address"/>
                      </div>
                      <div className="contact-detail">
                        <span className="contact-detail-title">UAE - </span>
                          <span className="contact-detail-link">Dubai south business center, Building C 3rd floor, Dubai logistics city, PO Box 390667, Dubai, UAE.</span>
                      </div>
                    </div>
                    {settingInfo?.email && settingInfo.email !== null && (
                      <div className="detail-mdiv">
                        <div className="detail-icon">
                          <img src={Icon2} alt="Mail Us On"/>
                        </div>
                        <div className="contact-detail">
                          <p className="contact-detail-title">Mail Us On</p>
                          
                            <a href={"mailto:" + settingInfo.email} className="contact-detail-link">{settingInfo.email}</a>
                        </div>
                      </div>
                    )}
                    {settingInfo?.primary_mobile_no && settingInfo.primary_mobile_no !== null && (
                      <div className="detail-mdiv">
                        <div className="detail-icon">
                          <img src={Icon3} alt="Call Us On"/>
                        </div>
                        <div className="contact-detail">
                          <p className="contact-detail-title">Call Us On</p>
                            <a href={"tel:" + settingInfo.primary_mobile_no.replace(/ /g,'')} className="contact-detail-link">{settingInfo.primary_mobile_no}</a>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
         </div>
       </div>
      </section>

    </Layout>
  );
}
import React from "react";
import {Link  } from "react-router-dom";
import Layout from "./layout.js";
import * as Constants from "../config/constants";

import { useTranslation } from "react-i18next";

import servicesImg from "../assets/images/services.svg";

import businessContinuity from "../assets/images/business-continuity.svg";
import cyberInsurance from "../assets/images/cyber-Insurance.svg";
import cyberSecurityTrainings from "../assets/images/cyber-security-trainings.svg";
import securityManagement from "../assets/images/security-management.svg";
import securityCompliance from "../assets/images/security-compliance.svg";
import cruxLogo from "../assets/images/crux-logo.png";
import dataPrivacyManagement from "../assets/images/data-privacy-management.svg";
import cyberTechnicalAssessments from "../assets/images/cyber-technical-assessments-icon.svg";
import otAndIot from "../assets/images/ot-iot-icon.svg";
import nexgenCyberServices from "../assets/images/nexgen-cyber-services.svg";



import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Home({ pageInfo }) {

  const {t} = useTranslation();


  return (
    <Layout pageName={t("products-services")}>
      <header className="breadcrumb-mdiv">
        <div className="container">
          <h1 className="page-title">{t("products-services")}</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{t("products-services")}</li>
          </ol>
        </div>
      </header>
      
      {/* services section */}
      
      <section className="section-padding services-section">
        <div className="container">
          <div className="row row-gap20" data-aos="fade-down">
            <div className="col-lg-5 order-lg-1 order-2 common-left-div" data-aos="fade-right" data-aos-delay="300">
              <img src={servicesImg} alt="services" className="common-img img-fluid"/>
            </div>
            <div className="col-lg-7 order-lg-2 order-1 common-right-div common-content-col">
              <div className="common-content-div">
                <h3 className="main-title">Our Products<span> and Services</span></h3>
                <p className="common-desc">We provide a comprehensive range of cybersecurity, information security, privacy, and business continuity products & services.</p>
                <p className="common-desc">What truly sets us apart is our foundation in design thinking. We solve cyber security problems in a user-centric manner. The human comes before the control.</p>
                <p className="common-desc">Our products and services keep evolving. We believe in thinking big and thinking differently. We are constantly evolving. Below is our list of services. Keep coming back here to see if we have a new product or service offering.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding services-section">
        <div className="container">
          <div className="row row-gap20 row-cols-xl-5 row-cols-md-3 row-cols-sm-2 row-cols-1">
            <div className="col" data-aos="fade-up"   data-aos-delay="200">
              <Link to={Constants.CYBERSECURITY_MANAGEMENT_PAGE_URL} className="services-item" title="Cybersecurity Management">
                <div className="services-icon-div">
                  <img src={securityManagement} alt="Cybersecurity Management" className="services-icon"/>
                </div>
                <h5 className="services-name">Cybersecurity Management</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="200">
              <Link to={Constants.BUSINESS_CONTINUITY_MANAGEMENT_PAGE_URL} className="services-item" title="Business Continuity Management">
                <div className="services-icon-div">
                  <img src={businessContinuity} alt="Business Continuity Management" className="services-icon"/>
                </div>
                <h5 className="services-name">Business Continuity Management</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="200">
              <Link to={Constants.TRAININGS_PAGE_URL} className="services-item" title="Trainings">
                <div className="services-icon-div">
                  <img src={cyberSecurityTrainings} alt="Trainings" className="services-icon"/>
                </div>
                <h5 className="services-name">Trainings</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="200">
              <Link to={Constants.CYBERSECURITY_COMPLIANCE_PAGE_URL} className="services-item" title="Cybersecurity Compliance">
                <div className="services-icon-div">
                  <img src={securityCompliance} alt="Cybersecurity Compliance" className="services-icon"/>
                </div>
                <h5 className="services-name">Cybersecurity Compliance</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="200">
              <Link to={Constants.CYBER_INSURANCE_PAGE_URL} className="services-item" title="Cyber Insurance">
                <div className="services-icon-div">
                  <img src={cyberInsurance} alt="Cyber Insurance" className="services-icon"/>
                </div>
                <h5 className="services-name">Cyber Insurance</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="200">
              <Link to={Constants.CYBER_TECHNICAL_ASSESSMENTS_PAGE_URL} className="services-item" title="Cyber Technical Assessments">
                <div className="services-icon-div">
                  <img src={cyberTechnicalAssessments} alt="Cyber Technical Assessments" className="services-icon"/>
                </div>
                <h5 className="services-name">Cyber Technical Assessments</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="200">
              <Link to={Constants.DATA_PRIVACY_MANAGEMENT_PAGE_URL} className="services-item" title="Data Privacy Management">
                <div className="services-icon-div">
                  <img src={dataPrivacyManagement} alt="Data Privacy Management" className="services-icon"/>
                </div>
                <h5 className="services-name">Data Privacy Management</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="200">
              <Link to={Constants.OT_IOT_PAGE_URL} className="services-item" title="OT and IoT">
                <div className="services-icon-div">
                  <img src={otAndIot} alt="OT and IoT" className="services-icon"/>
                </div>
                <h5 className="services-name">OT and IoT</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up"   data-aos-delay="200">
              <Link to={Constants.NEXTGEN_CYBER_SERVICES_PAGE_URL} className="services-item" title="Nexgen Cyber Services">
                <div className="services-icon-div">
                  <img src={nexgenCyberServices} alt="Nexgen Cyber Services" className="services-icon"/>
                </div>
                <h5 className="services-name">Nexgen Cyber Services</h5>
              </Link>
            </div>
            <div className="col" data-aos="fade-up" data-aos-delay="200">
              <Link to={Constants.CRUX_PAGE_URL} className="services-item2" title="Cyber Risk Underwriting Expert">
                <div className="services-icon-div">
                  <img src={cruxLogo} alt="CRUX" className="services-icon"/>
                </div>
                <p className="services-desc">Cyber Risk Underwriting Expert </p>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
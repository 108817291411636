import React from "react";
import { Link } from "react-router-dom";

const BlogsCard = (props = {}) => {
    return (
        <>
            <div className={`blog-card ${props.mainClassName ? props.mainClassName : ''}`}>
                <div className="img-div">
                    {props.imgSrc && 
                        <Link to={props.link}>
                            <img src={props.imgSrc} alt={props.title} className="img-fluid blog-img" />
                        </Link>
                    }
                </div>
                <div className="content-div">
                    {props.title &&
                        <div className="top-content">
                            <div className="date-info">
                                {props.date && <h6 className="info-text"><i className="fi fi-rr-calendar-day icon"></i> {props.date}</h6>}
                                {props.category && <Link to={props?.categoryLink} onClick={props.onCategoryButtonClick} className="info-text"><i className="fi fi-rr-list icon"></i> {props.category}</Link>}
                            </div>
                            <div className="title-content">
                                {props.link ? <Link to={props.link} className="title">{props.title}</Link> : <h6 className="title">{props.title}</h6>}
                                {props.text && <p className="p-text p-text-truncate text">{props.text}</p>}
                            </div>
                        </div>
                    }
                    <div className="bottom-content">
                        <div className="date-info">
                            {props.author && <h6 className="info-text"><i className="fi fi-rr-user icon"></i> {props.author}</h6>}
                            {props.readTime && <h6 className="info-text"><i className="fi fi-rr-clock-three icon"></i> {props.readTime}</h6>}
                        </div>
                        {props.to && <Link to={props.to} title="Read More" className="blog-btn" >Read More</Link>}
                    </div>
                </div>
            </div>
        </>
    )
}
export default BlogsCard;
import React from "react";
import '@fortawesome/fontawesome-free/css/all.css';
import { Link } from "react-router-dom";
import * as Constants from "../../config/constants";
import {decode} from 'html-entities';
import { useTranslation } from "react-i18next";


import AOS from 'aos';
import 'aos/dist/aos.css'; 
// ..
AOS.init();


export default function Footer({ settingInfo }) {
	const { t } = useTranslation();
	return (
		<footer>
			{settingInfo?.whatsapp_number && settingInfo.whatsapp_number !== null && settingInfo.whatsapp_number !== "" && (
				<div className={"whatsapp-main-fixed-part shadow whatsapp-icon whatsapp-" + (settingInfo?.whatsapp_position && settingInfo.whatsapp_position !== null && settingInfo.whatsapp_position === 'left' ? 'left' : 'right') }>
					<div className="circle">
						<a className=" link-unstyled cover-a-tag" rel="noopener noreferrer" target="_blank"
						href={"https://api.whatsapp.com/send?phone=" + settingInfo.whatsapp_number.replace(/[^0-9]/g, '')}>
						<i className="fab fa-whatsapp fa-2x text-white whatsaap-color"></i>
						</a>
					</div>
				</div>
			)}
			{settingInfo?.linkedin_link && settingInfo.linkedin_link !== null && settingInfo.linkedin_link !== "" && (
				<div className={"whatsapp-main-fixed-part shadow linkedin-fixed-icon whatsapp-icon whatsapp-" + (settingInfo?.whatsapp_position && settingInfo.whatsapp_position !== null && settingInfo.whatsapp_position === 'left' ? 'left' : 'right') }>
					<div className="circle">
						<a className=" link-unstyled cover-a-tag" rel="noopener noreferrer" target="_blank"
						href={settingInfo.linkedin_link}>
						<i className="fa-brands fa-linkedin fa-2x text-white whatsaap-color"></i>
						</a>
					</div>
				</div>
			)}

			{/* action section */}

			<section className="section-padding action-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 common-left-div common-content-col" data-aos="fade-right">
              <div className="common-content-div">
                <h3 className="main-title">Experience the Difference - <span>Discover our unique approach:</span></h3>
                <p className="common-desc">Get in touch to see how we can help elevate your cybersecurity strategies to the next level.</p>
              </div>
            </div>
            <div className="col-lg-2 d-flex" data-aos="fade-left">
						<Link to={Constants.CONTACT_US_PAGE_URL}  className="common-btn common-btn2 my-lg-auto ms-lg-auto" title="Reach Us Now">Reach Us Now</Link>
            </div>
          </div>
        </div>
      </section>

			<section className="footer footer-dark">
				<div className="container-fluid px-0">
					<div className="container">  
							<div className="row footer-mrow">
									<div className="col-lg-4 mb-lg-0 mb-3">
											<div className="footer-div-1">
								        {settingInfo.website_footer_logo && settingInfo.website_footer_logo !== null && settingInfo.website_footer_logo !== "" && (
													<Link className="navbar-brand" to={Constants.HOME_PAGE_URL}>
															<img src={Constants.BACKEND_FILE_URL + settingInfo.website_footer_logo} alt={settingInfo.site_title} className="brand-logo-img"/>
													</Link>
												)}
												{settingInfo.about_short_description && settingInfo.about_short_description !== null &&(
													<p className="footer-about" dangerouslySetInnerHTML={{__html: decode(settingInfo.about_short_description)}}></p>
												)}
											</div>
									</div>
									<div className="col-lg-2 col-md-4 col-sm-6 footer-com-pt mb-sm-0 mb-3 footer-quick">
											<h3 className="footer-title">Quick Links</h3>
											<ul className="list-unstyled footer-link-ul">
													<li><Link to={Constants.HOME_PAGE_URL} className="footer-link" title={t('home')}>{t('home')}</Link></li>
													<li><Link to={Constants.ABOUT_US_PAGE_URL} className="footer-link" title={t('about-us')}>{t('about-us')}</Link></li>
													<li><Link to={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} className="footer-link" title={t('products-services')}>{t('products-services')}</Link></li>
													<li><Link to={Constants.TEAM_PAGE_URL} className="footer-link" title={t('team')}>{t('team')}</Link></li>
													<li><Link to={Constants.CONTACT_US_PAGE_URL} className="footer-link" title={t('contact-us')}>{t('contact-us')}</Link></li>
													<li><Link to={Constants.PRIVACY_POLICY_PAGE_URL} className="footer-link" title={t('privacy-policy')}>{t('privacy-policy')}</Link></li>
											</ul>
									</div>
									<div className="col-lg-3 col-md-4 col-sm-6  footer-com-pt mb-sm-0 mb-3 footer-services">
											<h3 className="footer-title">{t('products-services')}</h3>
											<ul className="list-unstyled footer-link-ul">
													<li><Link to={Constants.CYBERSECURITY_MANAGEMENT_PAGE_URL} className="footer-link" title="Cybersecurity Management">Cybersecurity Management</Link></li>
													<li><Link to={Constants.BUSINESS_CONTINUITY_MANAGEMENT_PAGE_URL} className="footer-link" title="Business Continuity Management">Business Continuity Management</Link></li>
													<li><Link to={Constants.TRAININGS_PAGE_URL} className="footer-link" title="Trainings">Trainings</Link></li>
													<li><Link to={Constants.CYBERSECURITY_COMPLIANCE_PAGE_URL} className="footer-link" title="Cybersecurity Compliance">Cybersecurity Compliance</Link></li>
													<li><Link to={Constants.CYBER_INSURANCE_PAGE_URL} className="footer-link" title="Cyber Insurance">Cyber Insurance</Link></li>
													<li><Link to={Constants.CYBER_TECHNICAL_ASSESSMENTS_PAGE_URL} className="footer-link" title="Cyber Technical Assessments">Cyber Technical Assessments</Link></li>
													<li><Link to={Constants.DATA_PRIVACY_MANAGEMENT_PAGE_URL} className="footer-link" title="Data Privacy Management">Data Privacy Management</Link></li>
													<li><Link to={Constants.OT_IOT_PAGE_URL} className="footer-link" title="OT and IoT">OT and IoT</Link></li>
													<li><Link to={Constants.NEXTGEN_CYBER_SERVICES_PAGE_URL} className="footer-link" title="NextGen Cyber Services">NextGen Cyber Services</Link></li>
													<li><Link to={Constants.CRUX_PAGE_URL} className="footer-link" title="CRUX">CRUX</Link></li>
											</ul>
									</div>
									<div className="col-lg-3 col-md-4 footer-com-pt">
											<h3 className="footer-title">Our Location</h3>
											{settingInfo?.address && settingInfo.address !== null && (
												<>
													<div className="footer-location-mdiv">
															<i className="fi fi-rs-marker footer-location-icon"></i>
															<div className="footer-location-div">
																	<p className="footer-location"><span className="country-name">India -</span> <span dangerouslySetInnerHTML={{__html: decode(settingInfo.address)}}></span></p>
															</div>
													</div>
												</>
											)}
											<div className="footer-location-mdiv">
													<i className="fi fi-rs-marker footer-location-icon"></i>
													<div className="footer-location-div">
															<p className="footer-location"> <span className="country-name">UAE -</span> Dubai south business center, Building C 3rd floor, Dubai logistics city, PO Box 390667, Dubai, UAE.</p>
													</div>
											</div>
											{settingInfo?.primary_mobile_no && settingInfo.primary_mobile_no !== null && (
											<a href={"tel:" + settingInfo.primary_mobile_no.replace(/ /g,'')} className="footer-location-mdiv">
													<i className="fi fi-rr-mobile-button footer-location-icon"></i>
													<div className="footer-location-div">
															<p className="footer-contact-link">{settingInfo.primary_mobile_no}</p>
													</div>
											</a>
											)}
											{settingInfo?.email && settingInfo.email !== null && (
											<a href={"mailto:" + settingInfo.email} className="footer-location-mdiv">
													<i className="fi fi-rr-envelope footer-location-icon"></i>
													<div className="footer-location-div">
															<p className="footer-contact-link">{settingInfo.email}</p>
													</div>
											</a>
											)}
											{/* {((settingInfo.v_facebook_link && settingInfo.v_facebook_link !== null) || (settingInfo.v_twitter_link && settingInfo.v_twitter_link !== null) || (settingInfo.v_instagram_link && settingInfo.v_instagram_link !== null) || (settingInfo.v_youtube_link && settingInfo.v_youtube_link !== null) || (settingInfo.v_linkedin_link && settingInfo.v_linkedin_link !== null)) && (
												<>
													<hr  className="footer-hr"/>
													<div className="social-icon-div my-0">
														{settingInfo.v_facebook_link && settingInfo.v_facebook_link !== null && (
															<Link target="_blank" to={settingInfo.v_facebook_link} className="facebook">
																<i className="fi fi-brands-facebook"></i>
															</Link>
														)}
														{settingInfo.v_twitter_link && settingInfo.v_twitter_link !== null && (
															<Link target="_blank" to={settingInfo.v_twitter_link} className="instagram">
																<i className="fi fi-brands-twitter-alt"></i>
															</Link>
														)}
														{settingInfo.v_instagram_link && settingInfo.v_instagram_link !== null && (
															<Link target="_blank" to={settingInfo.v_instagram_link} className="instagram">
																<i className="fi fi-brands-instagram"></i>
															</Link>
														)}
														{settingInfo.v_youtube_link && settingInfo.v_youtube_link !== null && (
															<Link target="_blank" to={settingInfo.v_youtube_link} className="youtube">
																<i className="fi fi-brands-youtube"></i>
															</Link>
														)}
														{settingInfo.v_linkedin_link && settingInfo.v_linkedin_link !== null && (
															<Link target="_blank" to={settingInfo.v_linkedin_link} className="youtube">
																<i className="fi fi-brands-linkedin"></i>
															</Link>
														)}
													</div>
												</>
											)} */}
									</div>
							</div>
					</div>
					<div className="copyright-mdiv">  
						<div className="common-flot-div43 top0 right0"></div>
						<div className="copyright container">
							{settingInfo?.site_title && settingInfo.site_title !== null && (
							<p className="copyright-p">
								<span className="">Copyright &copy; - {(new Date().getFullYear())}. {settingInfo.site_title}. All Rights Reserved.</span>
								{settingInfo?.powered_by && settingInfo.powered_by !== null && (
									<span className="developed-span">Developed By <a href={(settingInfo?.powered_by_link && settingInfo.powered_by_link !== null) ? settingInfo.powered_by_link : void(0)} target="_blank" rel="noopener noreferrer">{settingInfo.powered_by}</a>.</span>
								)}
							</p>
							)}
						</div>
					</div>
				</div>
			</section>
		</footer>
	);
}

import React  from "react";
import { Link } from "react-router-dom";
import Layout from "../layout";
import * as Constants from "../../config/constants";
import { useTranslation } from "react-i18next";
import BlogsCard from "../../components/blogsCard";

export default function BlogsLayout({ pageName, children, categoryDetails, headingTitle, navLinkHtml, hideSearch, showRecommendedPosts, recommendedPosts, onClickCategoryButton, onSearchFormSubmit, register, isAllSelected, activeTab }) {
  const { t } = useTranslation();

  return (
    <Layout pageName={pageName} onClickCategoryButton={onClickCategoryButton}>
      <header className="breadcrumb-mdiv">
        <div className="container">
          {headingTitle && <h1 className="page-title t-truncate">{headingTitle}</h1>}
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            {navLinkHtml}
          </ol>
        </div>
      </header>
      <section className="blogs-list-sec">
        <div className="container">
          <div className="mcontent">
            <div className="inner-content">
              <div className="top-sec" data-aos="fade-down" data-aos-delay="300">
                {(!hideSearch) && (
                  <div className="search-div">
                    <form onSubmit={onSearchFormSubmit}>
                      <input type="text" className="form-control" name="search" {...register("search")} placeholder="Search..." />
                      <button type="submit" className="search-btn">
                        <i className="fa-solid fa-magnifying-glass icn">
                        </i>
                      </button>
                    </form>
                  </div>
                )}
                {(categoryDetails && categoryDetails?.length > 0) && 
                <><div className="category-list">
                  <Link to={Constants.BLOGS_PAGE_URL} onClick={(e) => onClickCategoryButton(e, true)} className={`category-btn ${(isAllSelected || (activeTab && activeTab === 'all')) ? 'active': ''}`}>All</Link>
                  {categoryDetails.map((category, key) => (
                    category?.value ? <Link key={key} to={`${Constants.CATEGORY_PAGE_URL}/${category?.slug}.html`} onClick={onClickCategoryButton} className={`category-btn ${(category?.active || (activeTab && activeTab === category.slug)) ? 'active': ''}`}>{category?.value}</Link>: <></>
                  ))}
                </div>
                <select name="" className="category-select form-select form-control" onChange={(e) => onClickCategoryButton(e, e?.target?.value ? false: true, true)}>
                  <option value="" selected={(isAllSelected || (activeTab && activeTab === 'all'))}>All</option>
                  {categoryDetails.map((category, key) => (
                    category?.value ? <option key={key} selected={(category?.active || (activeTab && activeTab === category.slug))} value={category?.slug}>{category?.value}</option>: <></>
                  ))}
                </select>
                </>}
              </div>
              {children}
            </div>
            {(showRecommendedPosts && recommendedPosts && recommendedPosts?.length > 0) && <div className="reco-post" data-aos="fade-down" data-aos-delay="300">
              <h6 className="title">Recommended Posts</h6>
              <div className="blogs-row">
                {recommendedPosts?.map((recommendedPost, key) => (
                  <BlogsCard key={key} imgSrc={recommendedPost?.image} title={recommendedPost?.title} text={recommendedPost?.short_description} date={recommendedPost?.publish_date} category={recommendedPost?.category} author={recommendedPost?.author} readTime={recommendedPost?.read_minutes ? `${recommendedPost?.read_minutes} Mins Read`: ``} to={Constants.SITE_URL + recommendedPost?.slug + '.html'} link={Constants.SITE_URL + recommendedPost?.slug + '.html'} categoryLink={`${Constants.CATEGORY_PAGE_URL}/${recommendedPost?.category_slug}.html`} />
                ))}
              </div>
            </div>}
          </div>
        </div>
      </section>
    </Layout>
  );
}
import React, {useRef, useEffect, useState} from "react";
import { NavLink, Link  } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import * as Constants from "../../config/constants";
import $ from "jquery";
import {apiCall, manageNetworkError, toggleNav} from '../../assets/js/common';
import { useTranslation } from "react-i18next";

window.jQuery = $;
window.$ = $;
global.jQuery = $;



export default function Header({ settingInfo, onClickCategoryButton }) {
  const { t } = useTranslation();
  const [notificationsDetails, setNotificationsDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const handleDropdownClick = (event) => {
    setIsOpen(!isOpen);
    event.stopPropagation();
  };
  useEffect(() => {
    const navMain = document.querySelector("#navMain");
    const minHeight = navMain.offsetHeight;
    document.querySelector(".main-navbar-wrapper").style.minHeight = minHeight + "px";
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getNotificationsDetails();
  }, []);
  const getNotificationsDetails = async() => {
    const notifications = await apiCall(  'get' ,  'notifications'  );
	  if( notifications.status !== false ){
	    setNotificationsDetails(notifications?.data?.data?.recordDetail ?? []);
	  } else {
		manageNetworkError(notifications);
	  }
  }
  const marqueeRef = useRef(null);

  const handleMouseOver = () => {
    if (marqueeRef.current) {
      marqueeRef.current.stop(); // Call the native stop method
    }
  };

  const handleMouseOut = () => {
    if (marqueeRef.current) {
      marqueeRef.current.start(); // Call the native start method
    }
  };

  return (
  <header>

  {/* <div className="d-lg-none mobile-view-none">Mobile Version of website is still under development</div>
    <div className="d-lg-block d-none"> */}
  {/* navbar start */}
  {/*
    #note: 
    1. 'twt-navbar' & 'twt-navbar-common' class are required, do not remove
    2. add 'fallen-top' or 'notch-top' for design variation. 
        - useful if want to add contact nav above main nav.
        - contact nav should be added right above navbar in 'main-navbar-wrapper'
  */}

  <div className="main-navbar-wrapper">
    <nav className="navbar twt-navbar twt-navbar-common navbar-expand-lg bg-light nav-light px-0 py-2 py-lg-3 flex-column" id="navMain">
      {(notificationsDetails && notificationsDetails?.length > 0) && <div class="marquee-mdiv">
        <div className="container px-0">
          <div className="marquee-tag">Notifications</div>
          {/* eslint-disable jsx-a11y/no-distracting-elements */}
          <marquee className="html-marquee" direction="left" behavior="scroll" scrollamount="8"  ref={marqueeRef} onMouseOver={handleMouseOver}onMouseOut={handleMouseOut}>
            {notificationsDetails.map((notification, key) => (
              notification?.link ?
              <Link key={key} to={notification?.link} className="marquee-content" target="_blank">{notification?.title}</Link>    
              : <p key={key} className="marquee-content">{notification?.title}</p>
            ))}
          </marquee>
          {/* eslint-disable jsx-a11y/no-distracting-elements */}
        </div>
      </div>}
      <div className="container">
        {settingInfo.website_logo && settingInfo.website_logo !== null && settingInfo.website_logo !== "" && (
          <NavLink className="navbar-brand p-3 p-lg-0" to={Constants.HOME_PAGE_URL}>
            <img src={Constants.BACKEND_FILE_URL + settingInfo.website_logo} alt={settingInfo.site_title} className="brand-logo-img"/>
          </NavLink>
        )}
        <button type="button" className="btn navbar-toggler border-0" id="slide-toggle" onClick={toggleNav}><span className="navbar-dash" /><span className="navbar-dash" /><span className="navbar-dash" /></button>
        { /* #note: remove 'flex-fill' class from below to align menu right */}
        <div className="slide navbar-slide flex-fill ms-lg-3" id="slideNav">
          <ul className="navbar-nav ms-auto p-0" id="elastic_parent" data-bs-targets=".nav-item">
            <li className="nav-item">
              <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} title={t('home')} to={Constants.HOME_PAGE_URL}>{t("home")}</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} title={t('about-us')} to={Constants.ABOUT_US_PAGE_URL}>{t("about-us")}</NavLink>
            </li>
            <li className="nav-item dropdown">
              <NavLink to={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} className={({ isActive }) => (isActive ? 'nav-link dropdown-toggle d-lg-flex d-none active' : 'nav-link dropdown-toggle d-lg-flex d-none')} title={t('products-services')}><span className="">{t('products-services')}</span><i className="fa-solid fa-chevron-down"></i></NavLink>
              <Link to={void(0)} className="nav-link dropdown-toggle d-flex d-lg-none" onClick={handleDropdownClick} title={t('products-services')} data-bs-toggle="dropdown"><span className="">{t('products-services')}</span><i className="fa-solid fa-chevron-down"></i></Link>
              <ul className="dropdown-menu">
                <li><NavLink to={Constants.PRODUCTS_AND_SERVICES_PAGE_URL} className="dropdown-item d-flex d-lg-none">All Products & Services</NavLink></li>

                <li><NavLink to={Constants.CYBERSECURITY_MANAGEMENT_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')} title="Cybersecurity Management">Cybersecurity Management</NavLink></li>

                <li><NavLink to={Constants.BUSINESS_CONTINUITY_MANAGEMENT_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')} title="Business Continuity Management">Business Continuity Management</NavLink></li>

                <li><NavLink to={Constants.TRAININGS_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')} title="Trainings">Trainings</NavLink></li>

                <li><NavLink to={Constants.CYBERSECURITY_COMPLIANCE_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')} title="Cybersecurity Compliance">Cybersecurity Compliance</NavLink></li>

                <li><NavLink to={Constants.CYBER_INSURANCE_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')} title="Cyber Insurance">Cyber Insurance</NavLink></li>

                <li><NavLink to={Constants.CYBER_TECHNICAL_ASSESSMENTS_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')} title="Cyber Technical Assessments">Cyber Technical Assessments</NavLink></li>

                <li><NavLink to={Constants.DATA_PRIVACY_MANAGEMENT_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')} title="Data Privacy Management">Data Privacy Management</NavLink></li>

                <li><NavLink to={Constants.OT_IOT_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')} title="OT and IoT">OT and IoT</NavLink></li>

                <li><NavLink  to={Constants.NEXTGEN_CYBER_SERVICES_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')} title="Nextgen Cyber Services">Nextgen Cyber Services</NavLink></li>
                
                <li><NavLink  to={Constants.CRUX_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')} title="CRUX">CRUX</NavLink></li>

              </ul>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} title={t('team')} to={Constants.TEAM_PAGE_URL}>{t("team")}</NavLink>
            </li>
            <li className="nav-item"> 
              <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} title={t('blogs')} to={Constants.BLOGS_PAGE_URL} onClick={onClickCategoryButton ?? null} >{t("blogs")}</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => (isActive ? 'nav-link pe-0 active' : 'nav-link pe-0')} title={t('contact-us')} to={Constants.CONTACT_US_PAGE_URL}>{t("contact-us")}</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  {/* navbar end  */}
  </header>
  );
}
import React, {useState, useEffect}  from "react";
import {  Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as Constants from "../../config/constants";
import { useTranslation } from "react-i18next";
import { apiCall , manageNetworkError } from "../../assets/js/common";
import BlogsLayout from "./blogs-layout";
import BlogsCard from "../../components/blogsCard";
import { useForm } from "react-hook-form";

export default function Blogs({ pageName }) {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [filterData, setFilterData] = useState({});
  const [renderRequired, setRenderRequired] = useState(true);
  const [recordDetails, setRecordDetails] = useState({
    blogs: [],
    tags: [],
    categories: [],
  });
  const [headingTitle, setHeadingTitle] = useState(pageName);
  const [firstRequest, setFirstRequest] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [activeTab, setActiveTab] = useState('all');

  const getRecordDetails = async (url, data, page, isFirstRequest, isTagePageRequest = false) => {
    if (isFirstRequest) {
      filterData.filter_request = 1;
    } else {
      filterData.filter_request = 0;
    }
    if (data && data?.search) {
      filterData.search = data?.search;
    } else {
      filterData.search = null;
    }
    filterData.currentPage = page;
    const getRecordDetail = await apiCall('post', url, filterData, showLoader, { isPageRequest: true, navigate: navigate });
    if (getRecordDetail?.status !== false) {
      if (getRecordDetail?.data?.data) {
        if (filterData.currentPage === 1) {
          setRecordDetails(getRecordDetail?.data?.data);
          if (isTagePageRequest) {
            const tag = getRecordDetail?.data?.data?.tags?.find(value => value?.slug === slug?.replace(".html", ""));
            if (tag && tag?.value) {
              setHeadingTitle(tag?.value);
            }
          }
        } else {
          setRecordDetails(recordDetails => ({
            ...recordDetails,
            blogs: [...recordDetails?.blogs, ...getRecordDetail?.data?.data?.blogs]
          }));
        }
        setCurrentPage(currentPage + 1);
      }
    } else {
      manageNetworkError(getRecordDetail);
    }
  }

  useEffect(() => {
		if (renderRequired && window.location.href) {
      setValue("search", "");
      switch (true) {
        case window.location.href.includes(Constants.CATEGORY_PAGE_URL):
          handleActiveInactiveCategoryBtn(true);
          if (!slug) {
            navigate(Constants.HOME_PAGE_URL);
          }
          setActiveTab(slug);
          setCurrentPage(1);
          getRecordDetails(Constants.CATEGORY_PAGE_URL + (slug ? "/" + slug?.replace(".html", ""): ""), null, currentPage, firstRequest, false);
          break;
        case window.location.href.includes(Constants.TAG_PAGE_URL):
          if (!slug) {
            navigate(Constants.HOME_PAGE_URL);
          }
          setActiveTab('all');
          setCurrentPage(1);
          getRecordDetails(Constants.TAG_PAGE_URL + (slug ? "/" + slug?.replace(".html", ""): ""), null, currentPage, firstRequest, true);
          break;
        case window.location.href.includes('/search-blog'):
          setActiveTab('all');
          setHeadingTitle(`Showing Results For : ${searchParams?.get('s')}`);
          setCurrentPage(1);
          getRecordDetails('blogs', { search: searchParams?.get('s') }, currentPage, true, false);
          break;
        default:
          setActiveTab('all');
          handleActiveInactiveCategoryBtn(true);
          getRecordDetails('blogs', null, currentPage, firstRequest);
          break;
      }
      setRenderRequired(false);
      if (firstRequest) setFirstRequest(false);
    }
    // eslint-disable-next-line
  }, [renderRequired]);

  const renderMoreData = () => {
    setRenderRequired(true);
    setShowLoader(true);
  }

  const onClickCategoryButton = (e, notShowText = false, isSelectField = false) => {
    setRenderRequired(true);
    setCurrentPage(1);
    setFirstRequest(true);
    setShowLoader(true);
    if (e?.target?.innerText && !notShowText) {
      if (isSelectField) {
        setHeadingTitle(e.target.options[e.target.selectedIndex].innerText ?? t('category'));
        navigate(Constants.CATEGORY_PAGE_URL + "/" + e?.target?.value + ".html");
      } else {
        setHeadingTitle(e?.target?.innerText);
      }
      handleActiveInactiveCategoryBtn(false);
    } else {
      handleActiveInactiveCategoryBtn(true);
      setHeadingTitle(t('blogs'));
      if (isSelectField) {
        navigate(Constants.BLOGS_PAGE_URL);
      }
    }
  }

  const handleActiveInactiveCategoryBtn = async (active) => {
    // const items = document.querySelectorAll('.category-btn');
    // if (active) {
    //   items?.forEach((i) => i?.classList?.remove('active'));
    //   items[0]?.classList.add('active');
    // } else {
    //   items[0]?.classList.remove('active');
    // }
  }

  const {
    register,
    handleSubmit,
    setValue
  } = useForm();

  const onSubmit = (data) => {
    if (data?.search) {
      setShowLoader(true);
      setCurrentPage(1);
      navigate("/" + Constants.BLOG_SEARCH_PAGE_URL + "?s=" + data?.search);
      setRenderRequired(true);
      setFirstRequest(false);
    }
  }

  return (
    <BlogsLayout 
      pageName={pageName} 
      categoryDetails={recordDetails?.categories || []} 
      headingTitle={headingTitle}
      navLinkHtml={
        <li className="breadcrumb-item active" aria-current="page">{t("blogs")}</li>
      }
      onClickCategoryButton={onClickCategoryButton}
      onSearchFormSubmit={handleSubmit(onSubmit)}
      register={register}
      isAllSelected={recordDetails?.isAllSelected || false}
      activeTab={activeTab}
    >
      <div className="bottom-sec" data-aos="fade-left" data-aos-delay="300">
        {recordDetails?.blogs?.length > 0 ? 
          <>
            <div className="blogs-row">
              {recordDetails?.blogs?.map((blogs, key) => (
                <BlogsCard key={key} imgSrc={blogs?.image} title={blogs?.title} text={blogs?.short_description} date={blogs?.publish_date} category={blogs?.category} author={blogs?.author} readTime={blogs?.read_minutes ? `${blogs?.read_minutes} Mins Read`: ``} to={Constants.SITE_URL + blogs?.slug + '.html'} link={Constants.SITE_URL + blogs?.slug + '.html'} categoryLink={`${Constants.CATEGORY_PAGE_URL}/${blogs?.category_slug}.html`} onCategoryButtonClick={onClickCategoryButton} />
              ))}
            </div>
            {recordDetails?.totalRecords !== recordDetails?.blogs?.length && <button type="button" className="blog-btn m-auto" title="View More" onClick={renderMoreData}>View More</button>}
          </>
        : <div className="no-record-div">
            <h6 className="no-record-text">No Record Found</h6>
            <Link to={Constants.BLOGS_PAGE_URL} onClick={(e) => onClickCategoryButton(e, true)} className="blog-btn" title="Go To Blog">Go To Blog</Link>
        </div>}
      </div>
    </BlogsLayout>
  );
}
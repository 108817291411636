import React from "react";
import {Link} from "react-router-dom";
import Layout from "../layout";
import * as Constants from "../../config/constants";
import { useTranslation } from "react-i18next";
import blogImg from "../../assets/images/blog-img.png";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import BlogsCard from "../../components/blogsCard";
// ..
AOS.init();

export default function DesignBlogDetails() {

  const { t } = useTranslation();

  return (
    <Layout pageName={"Blog Details"}>
      <header className="breadcrumb-mdiv blog-details">
        <div className="container">
          <h1 className="page-title t-truncate">Lorem lipsum is simply dummy text...</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link  to={Constants.HOME_PAGE_URL} title={t("home")}>{t("home")}</Link></li>
            <li className="breadcrumb-item active" aria-current="page"><Link  to={Constants.DESIGN_BLOGS_PAGE_URL} title={t("blogs")}>{t("blogs")}</Link></li>
          </ol>
        </div>
      </header>

      <section className="blogs-list-sec blog-details-sec">
        <div className="container">
          <div className="mcontent">
            <div className="inner-content">
              <div className="top-sec" data-aos="fade-down" data-aos-delay="300">
                <div className="search-div"><input type="text" className="form-control" name="search_blogs" placeholder="Search..." /><button type="button" className="search-btn"><i className="fa-solid fa-magnifying-glass icn"></i></button></div>
                <div className="category-list">
                  <button type="button" className="category-btn active">All</button>
                  <button type="button" className="category-btn">Cyber Security</button>
                  <button type="button" className="category-btn">Cyber Security</button>
                  <button type="button" className="category-btn">Cyber Security</button>
                  <button type="button" className="category-btn">Cyber Security</button>
                  <button type="button" className="category-btn">Cyber Security</button>
                  <button type="button" className="category-btn">Cyber Security</button>
                  <button type="button" className="category-btn">Cyber Security</button>
                  <button type="button" className="category-btn">Cyber Security</button>
                </div>
                <select name="" className="category-select form-select form-control">
                  <option value="">All</option>
                  <option value="Cyber Security">Cyber Security</option>
                  <option value="Cyber Security">Cyber Security</option>
                  <option value="Cyber Security">Cyber Security</option>
                  <option value="Cyber Security">Cyber Security</option>
                  <option value="Cyber Security">Cyber Security</option>
                </select>
              </div>
              <div className="row blog-row">
                  <div className="col-lg-8" data-aos="fade-down" data-aos-delay="300">
                    <div className="blog-detail-card">
                      <div className="top-content">
                        <img src={blogImg} alt="Lorem Ipsum is simply dummy text" className="img-fluid blog-detail-img" />
                        <div className="info">
                          <div className="left-cnt">
                            <h5 className="info-text"><i className="fi fi-rr-calendar-day icon"></i>16-Jul-2024</h5>
                            <button type="button" className="info-text"><i className="fi fi-rr-list icon"></i>Cyber Security</button>
                            <h5 className="info-text"><i className="fi fi-rr-user icon"></i>Raymond Delacruz</h5>
                          </div>
                          <div className="share-div">
                            <h5 className="title">Share:</h5>
                            <div className="contact-social">
                              <a href="https://www.facebook.com/" className="social-div"><i className="fi fi-brands-facebook icn"></i></a>
                              <a href="https://web.whatsapp.com/" className="social-div"><i className="fi fi-brands-whatsapp icn"></i></a>
                              <a href="https://x.com/?lang=en&mx=2" className="social-div"><i className="fi fi-brands-twitter-alt icn"></i></a>
                              <a href="https://in.linkedin.com/" className="social-div"><i className="fa-brands fa-linkedin icn"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bottom-content">
                        <div className="content">
                          <h6 className="title">Lorem Ipsum is simply dummy text</h6>
                          <p className="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                        </div>
                        <div className="content">
                          <h6 className="sub-title">Lorem Ipsum is simply dummy text</h6>
                          <p className="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        </div>
                        <div className="content">
                          <h6 className="sub-title">Lorem Ipsum is simply dummy text</h6>
                          <p className="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                        </div>
                        <div className="download-pdf-div">
                          <button type="button" className="blog-btn" title="Download PDF"><i className="fi fi-rr-file-pdf icn"></i> Download PDF</button>
                          <p className="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4" data-aos="fade-down" data-aos-delay="300">
                    <div className="right-content">
                      <div className="search-div right-cnt-search"><input type="text" className="form-control" name="search_blogs" placeholder="Search..." /><button type="button" className="search-btn"><i className="fa-solid fa-magnifying-glass icn"></i></button></div>
                      <div className="items">
                        <h6 className="item-title">Tags</h6>
                        <div className="tags-card">
                          <button type="button" className="tag">#tag1</button>
                          <button type="button" className="tag">#tag2</button>
                          <button type="button" className="tag">#taglorem</button>
                          <button type="button" className="tag">#taglorem</button>
                          <button type="button" className="tag">#taglorem</button>
                          <button type="button" className="tag">#taglorem</button>
                          <button type="button" className="tag">#taglorem</button>
                        </div>
                      </div>
                      <div className="items">
                        <h6 className="item-title">Subscribe To Our Newsletter</h6>
                        <div className="subscribe-div">
                          <input type="text" className="form-control" placeholder="Enter Your Email Address..." />
                          <label className="checkbox-div" htmlFor="lorem"><input type="checkbox" className="form-check-input" name="lorem" id="lorem" />Send me an email when you have new things</label>
                          <button type="button" className="sub-btn" title="Subscribe">Subscribe</button>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className="reco-post" data-aos="fade-down" data-aos-delay="300">
              <h6 className="title">Recommended Posts</h6>
              <div className="blogs-row">
                <BlogsCard imgSrc={blogImg} title={"Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text"} text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry"} date={"16-Jul-2024"} category={"Cyber Security"} author={"Raymond Delacruz"} readTime={"5 Mins Read"} to={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} link={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} />
                <BlogsCard imgSrc={blogImg} title={"Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text"} text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry"} date={"16-Jul-2024"} category={"Cyber Security"} author={"Raymond Delacruz"} readTime={"5 Mins Read"} to={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} link={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} />
                <BlogsCard imgSrc={blogImg} title={"Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text"} text={"Lorem Ipsum is simply dummy text of the printing and typesetting industry"} date={"16-Jul-2024"} category={"Cyber Security"} author={"Raymond Delacruz"} readTime={"5 Mins Read"} to={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} link={Constants.DESIGN_BLOG_DETAILS_PAGE_URL} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}